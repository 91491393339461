<template>
  <div>
    <div class="card p-2 bg-card">
      <div class="row">
        <div class="col-12 mb-1">
          <div class="d-flex justify-content-between align-items-center">
            <input
              v-model="filters.search"
              placeholder="Search"
              class="mr-bottom form-control input-sm mr-1 ml-1 mt-1"
              style="max-width: 200px"
            />
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <template
      v-if="this.$store.state.auth.permissions.includes('customer-list')"
    >
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <div
          class="position-relative table-responsive"
          style="min-height: 60vh"
        >
          <table class="table border mb-1">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Full Name</th>
                <th scope="col">Email</th>
                <th scope="col">Type</th>
                <th scope="col" class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, key, index) in users" :key="key">
                <td scope="row" class="text-center mx-1">
                  {{ (filters.page - 1) * pagination.perPage + index + 1 }}
                </td>
                <td role="cell">
                  <div
                    class="d-flex flex-column align-items-start justify-content-start"
                  >
                    <p
                      class="mb-0 font-weight-bold text-truncate"
                      style="max-width: 240px"
                      v-b-tooltip.hover.bottom.v-primary
                      :title="`${user.firstname} ${user.lastname}`"
                    >
                      {{ `${user.firstname} ${user.lastname}` }}
                    </p>
                  </div>
                </td>
                <td role="cell">
                  <div
                    class="d-flex align-items-center justify-content-start"
                    v-b-tooltip.hover.bottom.v-primary
                    :title="user.email"
                  >
                    <p
                      class="mb-0 font-weight-bold text-truncate"
                      style="max-width: 240px"
                    >
                      {{ user.email }}
                    </p>
                  </div>
                </td>

                <td>
                  <small class="text-success font-weight-bold">
                    <b-badge variant="primary">{{
                      mapUserType(user.user_type)
                    }}</b-badge></small
                  >
                </td>

                <td
                  role="cell"
                  class="align-center flex-display text-align-right"
                >
                  <div class="d-flex justify-content-center">
                    <b-dropdown
                      size="sm"
                      text="Select"
                      variant="primary"
                      dropleft
                    >
                      <b-dropdown-item
                        ><span class="align-middle">
                          <feather-icon icon="RepeatIcon" class="mr-50" />
                          <span class="align-middle">Restore</span>
                        </span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        ><span class="align-middle">
                          <feather-icon icon="TrashIcon" class="mr-50" />
                          <span class="align-middle">Delete Permenantly</span>
                        </span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-12 float-right">
            <b-pagination
              v-model="filters.page"
              :per-page="pagination.perPage"
              hide-goto-end-buttons
              :total-rows="pagination.lastPage * pagination.perPage"
              class="float-right"
            />
          </div>
        </div>
      </b-overlay>
    </template>
  </div>
</template>
<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BFormGroup,
  BFormCheckboxGroup,
  BCardText,
  BFormCheckbox,
  BPagination,
  BSidebar,
  BFormInput,
  BAvatar,
  BSpinner,
  BBadge,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BDropdownGroup,
  BOverlay,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import ResponseMixins from "../../mixins/ResponseMixins";
export default {
  mixins: [ResponseMixins],
  components: {
    vSelect,
    BFormGroup,
    BFormCheckboxGroup,
    BCardText,
    BFormCheckbox,
    BPagination,
    BSidebar,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BAvatar,
    BSpinner,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownGroup,
    BOverlay,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      users: {},
      filters: {
        search: null,
        page: 1,
      },
      pagination: {
        total: 1,
        perPage: 10,
        lastPage: 1,
      },
      showOverlay: false,
    };
  },
  watch: {
    filters: {
      deep: true,
      handler: function (newValue) {
        this.getDeletedUsersAndSetData(newValue);
      },
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.getDeletedUsersAndSetData(this.filters);
    },

    getDeletedUsersAndSetData(params) {
      this.showOverlay = true;
      const newParams = {};
      Object.keys(params).forEach((key) => {
        if (params[key] != null || params[key] != "") {
          newParams[key] = params[key];
        }
      });
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/user/get?include_deleted=1`,
        params: newParams,
      };
      this.$http(options)
        .then((res) => {
          const newUsers = {};
          res.data.data.data.forEach((user) => {
            newUsers[user._id] = user;
          });
          this.users = newUsers;
          this.$set(this.filters, "page", res.data.data.current_page);
          this.$set(this.pagination, "lastPage", res.data.data.last_page);
          this.$set(this.pagination, "perPage", res.data.data.per_page);
          this.$set(this.pagination, "total", res.data.data.total);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    mapUserType(type) {
      switch (type) {
        case "GRC_user":
          return "Consultant";

        case "Customer":
          return "Customer";

        case "Vendor":
          return "Third Party";

        case "Portal_user":
          return "Portal User";
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
th {
  background: #472183 !important;
  color: #fff;
  text-transform: capitalize !important;
}
</style>
